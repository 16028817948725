import React, {useState} from "react";
import { CSVLink } from "react-csv";
import Layout from "../components/layout"
import {expander} from '../constants/iterators';
import clone from 'lodash/clone'
import moment from 'moment'
import {graphql} from 'gatsby'



export default ({data: {allDatoCmsActivity, allDatoCmsEvent}}) => {
  let events = allDatoCmsEvent.edges.flatMap(e=> e.node)
  let locatables = allDatoCmsActivity.edges.flatMap(e=> e.node)
  const [selectedEvent, setEvent] = useState("")

  // TODO: handle locatables with no schedule items
  locatables = locatables
    .filter(locatable => locatable.scheduleItems && locatable.scheduleItems.length !== 0)
    .filter(locatable => selectedEvent.length === 0 || locatable.events.find(e => e.id === selectedEvent))
    .map(locatable => {
      locatable.expandedScheduleItems = locatable.scheduleItems
        .flatMap(scheduleItem => {
          const startDate = moment(scheduleItem.startDate  || undefined);
          const endDate = moment(scheduleItem.endDate || undefined);
          const numberOfOccurances = Math.max(Math.abs(startDate.diff(endDate, 'days')) + 1, 1);
          return ((n) => {
              let returnVal = [];
              for(let i = 0; i < n; i++){
                const date = startDate.format("YYYY-MM-DD");
                startDate.add(1, 'day');
                returnVal.push(date);
              }
              return returnVal;
          })(numberOfOccurances).map(date => {
              const recurring = clone(scheduleItem);
              recurring.startDate = date;
              recurring.endDate = date;
              recurring.startTime = scheduleItem.startTime || "12:00 AM"
              recurring.endTime = scheduleItem.endTime || "11:59 PM"
              recurring.id = scheduleItem.id + "-" + date + "-" + (scheduleItem.startTime || "allday");
              return recurring;
            })
        })
      return locatable;
    })
    .flatMap(expander("expandedScheduleItems", "scheduleItem"))
    .sort((a,b) => {
      if (!a.scheduleItem && b.scheduleItem) return -1;
      if (a.scheduleItem && !b.scheduleItem) return 1;
      if (!a.scheduleItem && !b.scheduleItem) return 0;
      const {startDate: aStartDate, startTime: aStartTime} = a.scheduleItem;
      const {startDate: bStartDate, startTime: bStartTime} = b.scheduleItem;
      const aStartDateTime = moment(aStartDate + " " + aStartTime, "YYYY-MM-DD h:m A");
      const bStartDateTime = moment(bStartDate + " " + bStartTime, "YYYY-MM-DD h:m A");
      return aStartDateTime - bStartDateTime;
    })
    .reduce((reportables, locatable) => {
      locatable.subcategory = locatable.subcategories.map(l => l.title).join(", ")
      locatable.scheduleItemTitle = locatable.scheduleItem.title
      locatable.startDate = locatable.scheduleItem.startDate
      locatable.endDate = locatable.scheduleItem.endDate
      locatable.startTime = locatable.scheduleItem.startTime
      locatable.endTime = locatable.scheduleItem.endTime
      locatable.locationTitle = locatable.location && locatable.location.title
      locatable.eventTitle = locatable.events.map(e => e.title).join(", ")
      reportables.push(locatable);
      return reportables
    }, [])
  return (
    <Layout>
      <section className="uk-section">
        <div className="uk-container">
          <div>
            <select onChange={(e) => setEvent(e.target.value)} className="uk-select uk-form-width-medium uk-margin-bottom">
              <option value="">All Events</option>
              {
                events.map(e => <option value={e.id}>{e.title}</option>)
              }

            </select>
          </div>
          <CSVLink
            className="uk-button uk-button-primary"
            filename="schedule.csv"
            headers={[
              {label: "Activity Title", key: "title"},
              {label: "Sub-Category", key: "subcategory"},
              {label: "Location", key: "locationTitle"},
              {label: "Schedule Item Title", key: "scheduleItemTitle"},
              {label: "Date", key: "startDate"},
              {label: "Start Time", key: "startTime"},
              {label: "End Time", key: "endTime"},
              {label: "Event", key: "eventTitle"}
            ]}
            data={locatables}>Export Schedule</CSVLink>
        </div>
      </section>

    </Layout>
  )
}

export const query = graphql`
  query ScheduleReport {
    allDatoCmsActivity {
      edges {
        node {
          ...ActivityFields
        }
      }
    }
    allDatoCmsEvent {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`
